import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { EnterpriseApiPermissions, useGetOrganisation } from '../../../../../app/ApiGen';
import { useCurrentOrganisation } from '../../../../../app/CurrentOrganisationProvider';
import { OrganisationTier } from '../../../../../app/enums';
import { SwitchValue } from '../../../../../components/CustomFields/Switch';
import { DataErrorHandler } from '../../../../../components/ErrorHandler';
import { Card, Loading } from '../../../../../design-system';
import { SubState } from '../../../../../store/types';
import { normalise } from '../../../../../utils/request';

const FetchContext: React.FC<{ organisation: SubState<'organisation'> }> = ({ organisation }) => {
  const { data, error, loading, refetch } = useGetOrganisation({
    orgSlug: organisation.slug,
  });

  if (loading) {
    return <Loading />;
  }
  const apiDetails = data ? normalise(data).apiPermissions : undefined;

  if (error) {
    return (
      <DataErrorHandler
        description={error.status === 403 ? 'Not authorized to view this content' : 'Unable to load API permissions'}
        error={error}
        refetch={refetch}
        type="embedded"
      />
    );
  }

  if (!apiDetails) {
    return <DataErrorHandler description="No API permissions found" error={error} refetch={refetch} type="embedded" />;
  }

  const attributePrefixes = {
    allChargePointsList: 'List all charge points',
    chargePointRead: 'Read charge point',
    chargePointLoadManagementUpdate: 'Update charge point load management',
    chargePointSessionsList: 'List charge point sessions',
    chargePointMeterValuesList: 'List  meter values',
    allSessionsList: 'List all sessions',
  };

  return (
    <Card>
      <Box>
        <Grid container justify="space-between" spacing={2}>
          <Grid item xs={8}>
            <Typography style={{ padding: '16px' }} color="textSecondary" variant="body2" paragraph>
              API Permissions
            </Typography>
          </Grid>
        </Grid>

        <Box p={2} pt={0}>
          <Grid container>
            <Grid item xs={5}>
              <Typography color="textSecondary" variant="body2" paragraph>
                Feature
              </Typography>
            </Grid>
            <Grid item xs={7} style={{ marginLeft: '-14px' }}>
              <Typography color="textSecondary" variant="body2" paragraph>
                Status
              </Typography>
            </Grid>
            <Divider style={{ margin: '8px 0px' }} />
          </Grid>
          {(Object.keys(attributePrefixes) as (keyof EnterpriseApiPermissions)[]).map((attribute) => (
            <Grid container>
              <Grid item xs={5}>
                <Typography color="textSecondary" variant="body2" paragraph>
                  {`${attributePrefixes[attribute]}`}
                </Typography>
              </Grid>
              <Grid item xs={7} style={{ marginLeft: '-20px' }}>
                <SwitchValue value={!!apiDetails[attribute]} labels={{ true: 'Enabled', false: 'Disabled' }} />
              </Grid>
            </Grid>
          ))}
        </Box>
      </Box>
    </Card>
  );
};

export const APIPermissions: React.FC = () => {
  const { organisation, loading } = useCurrentOrganisation();
  if (organisation?.tier !== OrganisationTier.Enterprise) {
    return <></>;
  }

  if (loading || !organisation) {
    return <Loading />;
  }

  return <FetchContext organisation={organisation} />;
};
